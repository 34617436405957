import React, { useRef, useState, useEffect } from "react";
import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import TourList from "../../components/TourList";
import BlogList from "../../components/BlogList";
import Testimonial from "../../components/Testimonial";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollToTop";
import CategorySection from "../../components/CategorySection";
import ContactModal from "../../components/ContactModal";
import SpeedDial from "../../components/SpeedDial";
import BottomTab from "../../components/BottomTab";

const normalizeCategoryName = (name) =>
  name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]/g, ""); // Boşlukları "-" yap, özel karakterleri kaldır.

const Home = () => {
  const tourListRef = useRef(null); // TourList için referans
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToCategory = (categoryName) => {
    const normalizedCategory = normalizeCategoryName(categoryName);
    const categoryElement = tourListRef.current?.querySelector(
      `[data-category="${normalizedCategory}"]`
    );

    if (categoryElement) {
      const offset = -100; // Pozisyonu ayarlamak için
      const topPosition =
        categoryElement.getBoundingClientRect().top + window.scrollY + offset;

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    } else {
      console.error("Category not found:", normalizedCategory);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <Header scrollToCategory={scrollToCategory} />

      {/* Bottom Tab for Mobile */}
      {isMobile && <BottomTab />}

      {/* ContactModal and SpeedDial for Web */}
      {!isMobile && (
        <>
          <ContactModal />
          <SpeedDial />
        </>
      )}

      {/* Main Content */}
      <div className="flex-grow pt-24 px-4">
        {/* Only render SearchBar if not mobile */}
        <SearchBar />
        
        <CategorySection scrollToCategory={scrollToCategory} />
        <div ref={tourListRef}>
          <TourList />
        </div>
        <Testimonial />
        <BlogList />
        <ScrollToTop />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
