import React from "react";
import {
  FaRegSmile,
  FaShieldAlt,
  FaMoneyBillWave,
  FaClock,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const WhyChooseUs = () => {
  const { t, i18n } = useTranslation();

  const features = [
    {
      icon: <FaRegSmile className="text-4xl text-orange-500" />,
      title: t("whyChooseUs.customerSatisfaction.title"),
      description: t("whyChooseUs.customerSatisfaction.description"),
    },
    {
      icon: <FaShieldAlt className="text-4xl text-orange-500" />,
      title: t("whyChooseUs.trustedService.title"),
      description: t("whyChooseUs.trustedService.description"),
    },
    {
      icon: <FaMoneyBillWave className="text-4xl text-orange-500" />,
      title: t("whyChooseUs.affordablePrice.title"),
      description: t("whyChooseUs.affordablePrice.description"),
    },
    {
      icon: <FaClock className="text-4xl text-orange-500" />,
      title: t("whyChooseUs.support247.title"),
      description: t("whyChooseUs.support247.description"),
    },
  ];

  return (
    <section className="py-2 mt-2">
      <div className="container mx-auto px-4">
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center hover:shadow-xl transition-shadow duration-300"
            >
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-lg font-semibold text-[#4F4A45]">
                {feature.title}
              </h3>
              <p className="text-sm text-[#6C5F5B] mt-2">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
