import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LANGUAGE_ROUTES = {
  en: "tour",
  tr: "tur",
  de: "reisen",
  ru: "тур",
};

const WEEK_DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const TourCard = ({ tour, currentLanguage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCardClick = () => {
    const route = LANGUAGE_ROUTES[currentLanguage] || "tour";
    navigate(`/${route}/${tour.slug?.[currentLanguage]}`);
  };

  // Mock capacity data for demonstration
  const mockCapacity = WEEK_DAYS.reduce((acc, day) => {
    acc[day] = Math.random() > 0.5 ? Math.floor(Math.random() * 20) + 1 : 0;
    return acc;
  }, {});

  return (
    <div
      onClick={handleCardClick}
      className="bg-white shadow-lg rounded-md overflow-hidden flex flex-col transition-transform transform hover:scale-105 cursor-pointer"
    >
      <img
        src={tour.image}
        alt={tour.title?.[currentLanguage] || "No Title"}
        className="h-48 w-full object-cover"
      />
      <div className="p-4 flex flex-col justify-between flex-grow">
        <h3 className="text-lg font-bold text-[#4F4A45] mb-2">
          {tour.title?.[currentLanguage] || "No Title"}
        </h3>
        <p className="text-sm text-[#6C5F5B] mb-1">
          {tour.description?.[currentLanguage]?.slice(0, 100) ||
            "No Description"}
          ...
        </p>
        <p className="text-[#ED7D31] font-bold">
          Price: €{tour.priceTable?.[0]?.price || "N/A"}
        </p>
        <div className="mt-1 flex flex-wrap gap-4">
          {WEEK_DAYS.map((day) => (
            <span
              key={day}
              className={` py-1 text-sm rounded ${
                mockCapacity[day] > 0
                  ? "text-[#ED7D31] font-bold"
                  : "text-gray-400 line-through"
              }`}
            >
              {day}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TourCard;
