import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LANGUAGE_ROUTES } from "../../config/routes";
import TourCard from "../../components/TourCard";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Testimonial from "../../components/Testimonial";
import SpeedDial from "../../components/SpeedDial";
import WhyChooseUs from "../../components/WhyChooseUs";
import BlogList from "../../components/BlogList";

const TourDetail = () => {
  const navigate = useNavigate();
  const currentLanguage = localStorage.getItem("language") || "en";
  const { slug } = useParams();
  const { t } = useTranslation();
  const [tour, setTour] = useState(null);

  //endpoint: https://travoktravel.com/api/v1/tour/getTourBySlug/${currentLanguage}/${slug}

  useEffect(() => {
    const fetchTour = async () => {
      try {
        const response = await axios.get(
          `https://travoktravel.com/api/v1/tour/getTourBySlug/${currentLanguage}/${slug}`
        );
        if (response.data.success) {
          setTour(response.data.data);
          console.log("Tour:", response.data.data);
        }
      } catch (error) {
        console.error("Error fetching tour:", error);
      }
    };

    fetchTour();
  }, [currentLanguage, slug]);

  return (
    <>
      <Header />
      <SpeedDial />

      <button
        onClick={() => navigate("/reservation")}
        className="fixed right-5 bottom-5 bg-[#ED7D31] text-white p-4 px-8 rounded-full shadow-lg hover:bg-[#6C5F5B] focus:ring-4 focus:outline-none focus:ring-blue-300 z-50"
      >
        Add To Cart
      </button>
      <Footer />
    </>
  );
};

export default TourDetail;
