import React from "react";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const SearchBar = ({  }) => {
  const { t } = useTranslation();

  return (
    <div className="container bg-[#ED7D31] py-2 px-2 shadow-md rounded-lg mx-auto">
      <form className="booking-form flex flex-wrap justify-between items-center gap-2">
        {/* Destination */}
        <div className="form-group flex-1 min-w-[100px]">
          <select className="form-select block w-full border-none bg-white rounded p-2 text-[#4F4A45] focus:outline-none focus:ring-2 focus:ring-[#F6F1EE]">
            <option value="Category" selected disabled>
              {t("search.category")}
            </option>
            <option value="Daily Boat Tour">{t("search.dailyBoatTour")}</option>
            <option value="Couple Tour">{t("search.coupleTour")}</option>
            <option value="Family Tour">{t("search.familyTour")}</option>
            <option value="Beach">{t("search.historyTours")}</option>
            <option value="Group Tour">{t("search.natureCultureTours")}</option>
          </select>
        </div>

        {/* Type */}
        <div className="form-group flex-1 min-w-[100px]">
          <select className="form-select block w-full border-none bg-white rounded p-2 text-[#4F4A45] focus:outline-none focus:ring-2 focus:ring-[#F6F1EE]">
            <option value="Tour" selected disabled>
              {t("search.capacity")}
            </option>
            <option value="Beach">5</option>
            <option value="Group Tour">10</option>
            <option value="Couple Tour">15</option>
            <option value="Family Tour">20</option>
          </select>
        </div>

        {/* Duration */}
        <div className="flex flex-wrap gap-4 w-full md:w-auto">
          <div className="form-group flex-1 min-w-[200px]">
            <select className="form-select block w-full border-none bg-white rounded p-2 text-[#4F4A45] focus:outline-none focus:ring-2 focus:ring-[#F6F1EE]">
              <option value="Day" selected disabled>
                {t("search.day")}
              </option>
              <option value="Monday">Monday</option>
              <option value="Deluxe">Tuesday</option>
              <option value="Deluxe">Wednesday</option>
              <option value="Deluxe">Thursday</option>
              <option value="Economy">Friday</option>
              <option value="Economy">Saturday</option>
              <option value="Economy">Sunday</option>
            </select>
          </div>
        </div>

        {/* Search Button */}
        <div className="form-btn w-full md:w-auto">
          <button className="bg-[#4F4A45] text-white px-4 py-4 rounded-full flex items-center justify-center transition-all duration-300 hover:bg-[#F6F1EE] hover:text-[#ED7D31] w-full">
            <FaSearch />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
