import React, { useEffect, useState } from "react";
import TourCard from "./TourCard";
import { useTranslation } from "react-i18next";

const normalizeCategoryName = (name) =>
  name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]/g, "");

const TourList = () => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const { i18n, t } = useTranslation();
  const currentLanguage = localStorage.getItem("language") || "en";

  useEffect(() => {
    const fetchTours = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://travoktravel.com/api/v1/tour/getAllToursWithouthLang`
        );
        const data = await response.json();
        if (data.success) {
          setTours(data.data);
        }
      } catch (error) {
        console.error("Error fetching tours:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTours();
  }, [currentLanguage]);

  if (loading) {
    return <p className="text-center text-[#6C5F5B]">{t("loading")}</p>;
  }

  // Turları kategoriye göre gruplandırma
  const groupedTours = tours.reduce((acc, tour) => {
    const category =
      tour.category?.[currentLanguage] || tour.category?.en || "unknown";
    const normalizedCategory = normalizeCategoryName(category);

    if (!acc[normalizedCategory]) {
      acc[normalizedCategory] = {
        categoryName: category,
        tours: [],
      };
    }
    acc[normalizedCategory].tours.push(tour);

    return acc;
  }, {});

  return (
    <div className="container mx-auto px-4 py-4">
      {Object.entries(groupedTours).map(
        ([normalizedCategory, categoryData]) => (
          <div
            key={normalizedCategory}
            data-category={normalizedCategory}
            className="mb-8"
          >
            <h3 className="sticky top-20 bg-[#F6F1EE] text-lg font-semibold text-[#4F4A45] mb-4 p-2 shadow-md z-10">
              {categoryData.categoryName}
            </h3>
            <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {categoryData.tours.map((tour) => (
                <TourCard
                  key={tour._id}
                  tour={tour}
                  currentLanguage={currentLanguage}
                />
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TourList;
